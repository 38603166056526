import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';

import { resetPassword } from '../../lib/api';
import { userLoggedIn } from '../../redux/actions';

import { Base, Jumbo } from '../../components/common';
import SEO from '../../components/seo';

import '../login.css';
import InfoModal from '../../components/common/modals/InfoModal';
import withLocation from '../../hoc/withLocation';
import StoreLogo from '../../components/common/StoreLogo';

const BUTTON_STYLE = {
  backgroundColor: 'var(--color-lylli-purple-dark)',
  borderColor: 'var(--color-lylli-purple-dark)',
  borderRadius: '32px',
  fontFamily: 'var(--font-headers)',
  marginBottom: '1rem',
  padding: '0.75rem 1.25rem',
};

class DeLoginPage extends React.Component {
  state = {
    loaded: false,
    noCode: false,
    completed: false,
    password: '',
    passwordRepeat: '',
    error: null,
    message: '',
    show: false,
  };

  componentDidMount() {
    const { code } = this.props.search;

    if (!code) {
      alert(
        'Ein unbekannter Fehler ist aufgetreten. Bitte kontaktiere kontakt@lylli.se falls der Fehler weiter besteht.'
      );
      this.setState({ noCode: true });
      return;
    }

    this.setState({ loaded: true });
  }

  handleSubmit = async (e) => {
    const { code } = this.props.search;
    const { password, passwordRepeat } = this.state;

    e.preventDefault();
    e.stopPropagation();

    if (password.length < 8) {
      this.setState({
        message: 'Das Password muss mindestens 8 Zeichen enthalten.',
        show: true,
      });
      return;
    }

    if (password !== passwordRepeat) {
      this.setState({
        message: 'Die Passwörter stimmen nicht überein.',
        show: true,
      });
      return;
    }

    let completed = false;
    let message;
    try {
      await resetPassword(password, code);
      message = 'Dein Passwort wurde nun geändert.';
      completed = true;
    } catch (ex) {
      message = ex.message;
    }

    this.setState({
      completed,
      message,
      show: true,
    });

    return false;
  };

  handlePasswordChanged = ({ target: { value } }) => {
    this.setState({ password: value });
  };

  handlePasswordRepeatChanged = ({ target: { value } }) => {
    this.setState({ passwordRepeat: value });
  };

  handleClose = async () => {
    this.setState({ show: false });
  };

  render() {
    const { completed, loaded, noCode, password, passwordRepeat, message, show } = this.state;

    if (!loaded) {
      return <div>Loading...</div>;
    }

    if (noCode) {
      return (
        <div>
          Ein unbekannter Fehler ist aufgetreten. Bitte kontaktiere{' '}
          <a href="mailto:kontakt@lylli.se">kontakt@lylli.se</a> falls der Fehler weiter besteht.
        </div>
      );
    }

    return (
      <Base hideFooter stealthMode>
        <SEO title="Zurücksetzen des Passwortes" />
        <Jumbo className="login">
          <InfoModal show={show} onClose={this.handleClose}>
            {message}
          </InfoModal>
          <Row>
            <Col lg={4} />

            {completed && !show && (
              <Col
                lg={4}
                style={{
                  padding: '1rem',
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  border: 'white 1px solid',
                }}
              >
                <h2>Dein Passwort wurde nun geändert</h2>
                <p style={{ textAlign: 'center' }}>
                  Jetzt kannst du dich mit deinem neuen Passwort in der App anmelden.
                </p>

                <p className="stores">
                  <StoreLogo lang={StoreLogo.LANG.DEU} trackerName="footer" type={StoreLogo.TYPES.APPLE} />
                </p>
              </Col>
            )}
            {!(completed && !show) && (
              <Col
                lg={4}
                style={{
                  padding: '1rem',
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  border: 'white 1px solid',
                }}
              >
                <h2>Zurücksetzen des Passwortes</h2>

                <p>Du bist dabei, dein Passwort zu ändern.</p>

                <Form onSubmit={this.handleSubmit}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Wähle ein neues Passwort</Form.Label>
                    <Form.Control
                      type="password"
                      value={password}
                      onChange={this.handlePasswordChanged}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Wiederhole dein neues Passwort</Form.Label>
                    <Form.Control
                      type="password"
                      value={passwordRepeat}
                      onChange={this.handlePasswordRepeatChanged}
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" style={BUTTON_STYLE} block>
                    Ändere Passwort
                  </Button>
                </Form>
              </Col>
            )}
            <Col lg={4} />
          </Row>
        </Jumbo>
      </Base>
    );
  }
}

export default connect(({ user }) => ({ user }), { actionUserLoggedIn: userLoggedIn })(
  withLocation(DeLoginPage)
);
